import React from "react";
import Youtube from "emersonbroga-youtube-embed";

import SEO from "src/components/SEO";
import CTA from "src/components/CTA";
import Footer from "src/sections/Footer";

import { BOOK_ES6, NAME, BASE_URL } from "src/helpers/config";

import "./ES6.scss";

const ES6 = () => {
  const seoProps = {
    title: `Livro: ${BOOK_ES6} - ${NAME}`,
    description:
      "Evolua ou entre em Extinção - Aprenda de uma vez por todas as novidades do ES6, como let, const, promises, generators e muito mais, nesse livro completo.",
    canonical: `${BASE_URL}/es6/`,
    image: `${BASE_URL}/meta/ogimage-es6.jpg`,
  };

  return (
    <div className="es6">
      <SEO {...seoProps} />
      <section className="header-promo">
        <div className="info">
          <p>Se você trabalha com JavaScript...</p>
          <h1 className="title">
            <em>Evolua</em>
            <span>ou entre em</span>
            <em>Extinção</em>
          </h1>
          <p>
            Enquanto o mercado de trabalho vai evoluindo e exigindo cada vez
            mais conhecimento você deve sempre estar à frente e sempre
            aprendendo as novidades que vão surgindo.
          </p>
          <CTA id="es6-demo" isModal={true} />
        </div>
        <div className="promo-video">
          <Youtube videoId="XcHNKlqlxkE" />
        </div>
        <div className="promo-cta ">
          <CTA id="es6" />
        </div>
      </section>
      <section className="text-section">
        <h2 className="title">
          Você já se sentiu frustrado, por olhar aqueles{" "}
          <em>códigos bonitos de JavaScript</em> e não entender muito bem o que
          está acontecendo?
        </h2>

        <p>
          Se você trabalha com JavaScript, eu tenho uma coisa pra te dizer,
          evolua ou entre em extinção.
        </p>

        <p>
          Você já olhou para aquela função com um * (asterísco) e não faz ideia
          do que é?
        </p>

        <p>
          Você sente vontade de contribuir com os milhares de repositórios de
          projetos em JavaScript mas acredita que não escreve um código JS à
          altura?
        </p>

        <p>Se você disse sim para alguma das perguntas acima, vem comigo.</p>

        <p>
          Após anos e anos trabalhando com JavaScript, eu decidi dedicar meu
          tempo para escrever um livro digital contando as Novidades do ES6.
        </p>

        <h2 className="title">
          E por que você precisa aprender <em>ES6</em> ?
        </h2>

        <p>
          Se você trabalha com JavaScript e ainda não está dominando as
          atualizações da linguagem você está ficando parado no tempo.
        </p>

        <p>
          Enquanto o mercado de trabalho vai evoluindo e exigindo cada vez mais
          conhecimento você deve sempre estar à frente e sempre aprendendo as
          novidades que vão surgindo.
        </p>

        <p>
          Eu sei que você deve achar que ES6 é só uma modinha e que vai passar,
          mas eu já vou te falar que não tem como o ES6 ser modinha, pois é uma
          evolução do próprio JavaScript, que está aí desde os primórdios da
          internet em 1995.
        </p>

        <p>
          Mesmo que os seus projetos pessoais ou os projetos da empresa que você
          trabalha não estejam usando ES6, você precisa se atualizar para não
          acumular déficits de conhecimento, pois depois será tarde demais.
        </p>

        <p>
          Os novos recursos do ES6, as novas sintaxes, as novas features e APIs
          vão te ajudar e vão também simplificar o seu código.
        </p>

        <p>
          Se você pretende trabalhar com os novos frameworks como React e VueJS
          é uma questão fundamental conhecer bem o ES6.
        </p>

        <p>
          Facilite sua vida, atualize-se e seja uma pessoa mais qualificada na
          sua jornada!
        </p>

        <h2 className="title">
          <em>JavaScript</em> : A Novidades do ES6
        </h2>

        <p>
          Após anos e anos trablhando com JavaScript, eu decidi dedicar meu
          tempo para escrever um livro digital contando as Novidades do ES6.
        </p>

        <p>
          Trazendo um guia prático e de fácil leitura com exemplos claros, este
          e-book veio para esclarecer suas dúvidas quanto a arrow-functions,
          rest operator, spread operator e muito mais.
        </p>

        <p>
          Deixe seu código com uma escrita mais limpa e clara, aproveite as
          novas funcionalidades e eleve o seu nível de JavaScript.
        </p>

        <p>
          Se especialize no que o mercado de trabalho está exigindo, seja um
          programador atualizado e mais valorizado.
        </p>

        <p>
          Você deve estar pensando, "Esse livro deve custar uma fortuna", mas
          comparado aos benefícios que ele vai trazer para sua carreira ele está
          quase de graça.
        </p>
      </section>

      <section className="section-cta full-width">
        <div>
          <h2 className="title">{BOOK_ES6}</h2>
          <div className="e-book">
            <img
              loading="lazy"
              src="https://emersonbroga.com/wp-content/uploads/2020/12/450x450-template-001.png"
              alt={BOOK_ES6}
            />
          </div>
          <CTA id="es6" />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ES6;
